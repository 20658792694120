import React, { Component } from 'react';

import { Link } from 'react-router-dom';


export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
      return (
        <>
          <section id="topbar" className="topbar d-flex align-items-center">
              <div className="container d-flex justify-content-center justify-content-md-between">
                  <div className="contact-info d-flex align-items-center">
                          <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:contact@example.com"> hiskenterprises@gmail.com</a></i>
                          <i className="bi bi-phone d-flex align-items-center ms-4"><span>+91-9873199277</span></i>
                  </div>
                  <div className="social-links d-none d-md-flex align-items-center">
                      <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
                      <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                      <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
                      <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
              </div>
    </div>

              </section>
          <header id="header" className="header d-flex align-items-center">

              <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                  <a href="index.html" className="logo d-flex align-items-center">
                      
                          <img src="assets/img/site-logo.png" alt=""/> 
                              {/*<h1>SpikeZone<span>.</span></h1>*/}
      </a>
                      <nav id="navbar" className="navbar">
                          <ul>
                              <li><Link to="/home"><b>HOME</b></Link></li>
                              <li><Link to="/aboutus"><b>ABOUT US</b></Link></li>
                              <li><Link to="/services"><b>SERVICES</b></Link></li>
                              <li><Link to="/team"><b>TEAM</b></Link></li>
                              <li><Link to="/blogs"><b>BLOG</b></Link></li>
                              <li><a href="/gallery"><b>GALLERY</b></a></li>
                              <li><Link to="/ourpartners"><b>OUR PARTENRS</b></Link></li>
                              <li className="dropdown"><Link to="#team"><span><b>PRODUCTS</b></span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
                                  <ul>
                                      <li><Link to="/antibirdnet">ANTI BIRD NET</Link></li>
                                      
                                      <li><Link to="/birdrepellent">BIRD REPELLENT</Link></li>
                                      <li><Link to="/birdspikes">BIRD SPIKES</Link></li>
                                      <li><Link to="/monkeyspikes">MONKEY SPIKES</Link></li>
                                  </ul>
                              </li>
                              <li><Link to="/contactus"><b>CONTACT US</b></Link></li>
                          </ul>
                      </nav>
                
      <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
                      <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>

    </div>
                  </header>
                  </>
    );
  }
}
