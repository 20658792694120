import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Ourpartners extends Component {
    static displayName = Ourpartners.name;

    render() {
        return (
            <>
                <section id="team" class="team">
                <div class="container" data-aos="fade-up">

                    <div class="section-header">
                        <h2>Our Partners</h2>
                                       </div>

                    <div class="row gy-4">

                        <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                            <div class="member">
                                <img src="assets/partners/Berger.jpg" class="img-fluid" alt=""/>
                                    
                                    <div class="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                    </div>
            </div>
                            </div>
                  
          <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                                <div class="member">
                                    <img src="assets/partners/DMRC.jpg" class="img-fluid" alt=""/>
                                       
                                        <div class="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                        </div>
            </div>
                                </div>
                      
          <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="300">
                                    <div class="member">
                                    <img src="assets/partners/Gail.jpeg" class="img-fluid" alt=""/>
                                            
                                            <div class="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                            </div>
            </div>
                                    </div>
          <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="400">
                                        <div class="member">
                                        <img src="assets/partners/PowerGrid.jpg" class="img-fluid" alt=""/>
                                               
                                                <div class="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                                </div>
            </div>
                            </div>



                            <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="400">
                                <div class="member">
                                    <img src="assets/partners/PuriConstructions.jpg" class="img-fluid" alt="" />

                                    <div class="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>
                              
        </div>

                                </div>
    </section>
            </>
        );
    }
}
