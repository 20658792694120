import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class Birdrepellent extends Component {
    static displayName = Birdrepellent.name;

    render() {
        return (
            <>
                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Bird Repellent</h2>
                            <p align="justify">


                               A variety of repellents have been tested and found
                                 to be effective, though some may be less effective
                                 than others due to the fact that birds are more likely 
                                 to become accustomed to the device and ignore it. 
                                 It is important to note that some repellents may be more effective
                                  in certain areas than others, 
                                 and that the solution to the pigeon dilemma is not to install
                                  a repellent. Instead, it is important to consider 
                                  what bird are attracted to before investing in any repellents, 
                                    as this will help to make more informed decisions
     
     
                            </p>
                            <br/>
                            <p align="justify">The following are the types of pigeon repellents:</p>
                            <p align="justify"><b>1. Spikes :--</b>Spikes are an effective and 
                                cost-effective way to physically exclude pigeons.
                                They are typically produced in strips and function on 
                                the assumption that pigeons will not be able to land on the spikes' 
                                pointed ends. Spikes are available in a variety of shapes and materials, 
                                including stainless steel and plastic, as well as blunt and pointed spikes,
                                depending on the requirements of the user. </p>
                            <p align="justify"><b>2. Gel and Pastes:--</b>
                                A gel is a type of goo that is sticky and transparent.
                                It is typically applied using a caulking gun to areas
                                where pigeons are present. Some gels contain an active ingredient, 
                                such as essential oils, which pigeons may find offensive. 
                                Birds will not land on treated areas due to their feet becoming stuck in the goo, 
                                and it may take them several hours to remove the goo.
                            </p>
                            <p align="justify"><b>3. Reflective Discs :-- </b>This bird repellent is
                                both aesthetically pleasing and inconspicuous, making it suitable for 
                                use in a variety of settings. It can be hung on windows, placed 
                                in close proximity to the garden, vehicle, and swimming pool. 
                                Generally, the set consists of reflectors containing three or 
                                more disc shapes. The disc shapes do not take up much area and do not damage the
                                exterior of a property.</p>
                        </div>



                    </div>

                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4 team" id="team" >

                            <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                                <div className="member">
                                    <img src="assets/birdrepellent/hawkbirdspcarer.jpg" className="img-fluid" alt="" />

                                    <div className="social">
                                       
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>

                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                                <div className="member">
                                    <img src="assets/birdrepellent/irritape.png" className="img-fluid" alt="" />

                                    <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="300">
                                <div className="member">
                                    <img src="assets/antibirdnet/whiteanitibirdnet.jpg" className="img-fluid" alt="" />

                                    <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="300">
                                <div className="member">
                                    <img src="assets/antibirdnet/purplebirdnet.jpg" className="img-fluid" alt="" />

                                    <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
            </>
        );
    }
}
