import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class NavFooter extends Component {
    static displayName = NavFooter.name;

    render() {
        return (
            <>

                <footer id="footer" className="footer">

                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-5 col-md-12 footer-info">
                                <a href="index.html" className="logo d-flex align-items-center">
                                    <img src="assets/img/site-logo.png" alt="" />
                                </a>
                                <p align="justify">SpikeZone is one of the top producers of bird spikes, 
                                    but we also produce spikes in all shapes and sizes. 
                                    We also make an effort to keep costs low and complexity high. 
                                    Our goal is to deliver the greatest product that is easy to use,
                                    inexpensive to purchase, and requires little maintenance.</p>
                                <div className="social-links d-flex mt-4">
                                    <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
                                    <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                                    <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
                                    <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><Link to="/home">Home</Link></li>
                                    <li>
                                    <Link to="/aboutus">About us</Link></li>
                                    <li><Link to="/services">Services</Link></li>
                                    <li><Link to="/services">Terms of service</Link></li>
                                    <li><Link to="/services">Privacy policy</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><Link to="/services">Spikes Sales</Link></li>
                                    <li><Link to="/services">Spikes Configuration</Link></li>
                                    <li><Link to="/services">Spikes Implementation</Link></li>
                                    <li><Link to="/services">Spikes Quality Detection</Link></li>
                                    <li><Link to="/services">Spikes Data Analysis</Link></li>
                                    <li><Link to="/services">Spikes Flow Chart</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                                <h4>Contact Us</h4>
                                <p>
                                    PLOT NO.82, 
                                    NEAR AMAN GARDEN,<br/>
                                    WEST KRISHNA VIHAR,<br />
                                    NAJAFGARH, NEW DELHI 110043 <br />
                                         <br/><br/>
                                    <strong>Phone:</strong> +91-9873199277<br/>
                                    <strong>Email:</strong> hiskenterprises@gmail.com<br/>
                                          </p>

                                        </div>

                                      </div>
                                    </div>

                                            <div className="container mt-4">
                                                <div className="copyright">
                                                    &copy; Copyright <strong><span>SpikeZone</span></strong>. All Rights Reserved
                                      </div>
                                                <div className="credits">
                                Designed by <a href="https://bootstrapmade.com/">SpikeZone</a>
                                                </div>
                                            </div>

                     </footer>

            </>
        );
    }
}
