import React, { Component } from 'react';
//import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { NavFooter } from './NavFooter';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
    <NavMenu />
       
    {this.props.children}

    <NavFooter/>
        
        </div>




    );
  }
}
