import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Antibirdnet extends Component {
    static displayName = Antibirdnet.name;

    render() {
        return (
            <>
                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Anti Bird Net</h2>
                            <p align="justify">


                                Anti Bird Net is the use of netting to prevent birds
                                from entering certain areas. Bird protection netting is 
                                available in a range of shapes and sizes, with the most 
                                common being a small mesh, either extruded or bi-oriented,
                                made of polypropylene. Black netting is the most commonly
                                used color, as it provides the best protection against sun rays. 
                                Additionally, white netting, which is often woven or knitted, can 
                                be available in smaller packages to meet the needs of backyard gardeners.
                                Professional netting is also available in jumbo rolls, which are often sold at retail
                                outlets to DIY farmers and offer considerable savings.


                            </p>
                        </div>

                        

                    </div>

                    <div className="container" data-aos="fade-up">
                    <div className="row gy-4 team" id="team" >

                        <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                            <div className="member">
                                    <img src="assets/antibirdnet/blackantibrdnet.jpg" className="img-fluid" alt="" />

                                <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                            <div className="member">
                                    <img src="assets/antibirdnet/greenantibirdnet.jpg" className="img-fluid" alt="" />

                                <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="300">
                            <div className="member">
                                    <img src="assets/antibirdnet/whiteanitibirdnet.jpg" className="img-fluid" alt="" />

                                <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                </div>
                            </div>
                        </div>


                            <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="300">
                                <div className="member">
                                    <img src="assets/antibirdnet/purplebirdnet.jpg" className="img-fluid" alt="" />

                                    <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>


                        </div>
                        </div>
                </section>
            </>
        );
    }
}
