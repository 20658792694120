import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class MonkeySpikes extends Component {
    static displayName = MonkeySpikes.name;

    render() {
        return (
            <>

                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Monkey Spikes</h2>
                            <p align="justify">
                                Monkeys are known for their intelligence,
                                which has caused them to cause a variety of 
                                issues in various regions. They are most commonly 
                                encountered in hilly or forest areas, and can cause
                                a great deal of disruption to those living in these areas. 
                                In most areas, monkeys are capable of stealing food, mobile
                                phones, and other items, as well as damaging property. 
                                Additionally, they can break into homes and cause a variety 
                                of other issues. The most effective way to prevent the appearance 
                                of these animals is to terrify them, 
                                which can be achieved by using a monkey spike.





                            </p>
                        </div>



                    </div>

                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4 team" id="team" >

                            <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                                <div className="member">
                                    <img src="assets/monkeyspike/monkeyspike1.jpg" className="img-fluid" alt="" />

                                    <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                                <div className="member">
                                    <img src="assets/monkeyspike/monkeyspike2.jpg" className="img-fluid" alt="" />

                                    <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>

                           
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
