import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Team extends Component {
    static displayName = Team.name;

    render() {
        return (
            <>
 
                

              
    <section  class="team">
                    <div class="container" data-aos="fade-up">

                        <div class="section-header">
                            <h2>Our Team</h2>
                                 </div>
                       
                    <div class="row gy-4">

                            <div class="col-xl-3" data-aos="fade-up" data-aos-delay="100">
                                <div class="member">
                                    {/*<img src="assets/img/team/team-1.jpg" class="img-fluid" alt=""/>*/}
                                    <h4>Raunak Sharma</h4>
                                        <span>Web  Developer</span>
                                        <div class="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                        </div>
            </div>
                            </div>

                           
                      
       

                            
                              
        
                                  
                        </div> 

                                    </div>
    </section>
                            
                                                
                                                        
            </>
        );
    }
}
