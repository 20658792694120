import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Services extends Component {
    static displayName = Services.name;

    render() {
        return (
            <>
                <section id="services" class="services sections-bg">
                    <div class="container" data-aos="fade-up">

                        <div class="section-header">
                            <h2>Our Services</h2>
                            <p></p>
                        </div>

                        <div class="row gy-4" data-aos="fade-up" data-aos-delay="100">

                            <div class="col-lg-4 col-md-6">
                                <div class="service-item  position-relative">
                                    <h3>Spikes Sales</h3>
                                    <img src={"assets/img/services/spikesales.png"} style={{ height: "120px", width: "200px" }} />

                                    {/*<div class="icon">
                                        <i class="bi bi-activity"></i>8
                                            </div>
                                    <h3>Spikes Sales</h3>*/}
                                    <br /><br />
                                    <p>Our bird and monkey spikes are made with the most up-to-date design.
                                        The spikes are longer, and small spikes are added to keep small critters
                                        away. This makes the bird and monkey spikes even more effective..</p>
                                    <Link to="#" className="readmore stretched-link">Bird Spikes Sales<i class="bi bi-arrow-right"></i></Link>
                                    <br/>
                                    <Link to="#" className="readmore stretched-link">Monkey Spikes Sales<i class="bi bi-arrow-right"></i></Link>

                                </div>
                            </div>
                  
          <div class="col-lg-4 col-md-6">
                                <div class="service-item position-relative">
                                    <h3>Spikes Configuration</h3>
                                    {/* <div class="icon">
                                        <i class="bi bi-broadcast"></i>
                                    </div>
                                    <h3>Spikes Configuration</h3>*/}
                                    <img src={"assets/img/services/spikesconfiguration.jpg"} style={{ height: "120px", width: "200px" }} />
                                    <br /><br />
                                    <p>If you are looking for a reliable and long-lasting solution to deter large 
                                        pest birds from a variety of building surfaces, bird spikes are the 
                                        ideal choice. Our system is designed to be durable, effective, and long-lasting, 
                                        and is discreet and easy to install. </p>
                                    
                                    <Link to="#" className="readmore stretched-link">Bird Spikes Configuration<i class="bi bi-arrow-right"></i></Link>
                                    <Link to="#" className="readmore stretched-link">Monkey Spikes Configuration<i class="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>
                  
          <div class="col-lg-4 col-md-6">
                                <div class="service-item position-relative">
                                    <h3>Spikes Implementation</h3>
                                    {/* <div class="icon">
                                        <i class="bi bi-easel"></i>
                                    </div>*/}
                                    <img src={"assets/img/services/spikeimplemenatioan.jpg"} style={{ height: "120px", width: "200px" }} />
                                    <br /><br />
                                    <p>Our Pest Bird Control Specialist team is professionally trained and guarantee that
                                        all work performed at heights is carried out professionally and safely. You can be assured that our technicians are equipped to address your pest bird issues, as well as provide helpful
                                        advice on other measures you can take to protect your property from other pest species.</p>
                                    <Link to="#" className="readmore stretched-link">Bird Spikes Implementation<i class="bi bi-arrow-right"></i></Link>
                                    <Link to="#" className="readmore stretched-link">Monkey Spikes Implementation<i class="bi bi-arrow-right"></i></Link>

                                </div>
                            </div>
                  
                                <div class="col-lg-4 col-md-6">
                                <div class="service-item position-relative">
                                    {/* <div class="icon">
                                        <i class="bi bi-bounding-box-circles"></i>
                                    </div>*/}
                                    <b><h3>Spikes Quality Detection</h3></b>
                                    <img src={"assets/img/services/qualitydetection.jpg"} style={{ height: "120px", width: "200px" }} />
                                    <br /><br />
                                    <p>Spikes quality detector is a software which detects the faults and qaulity of spikes and give the results as
                                    per insepct the image of the spikes of the prodeucs adn give the accurate outcome to detech.</p>
                                    <a href="#" classd="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                  
          <div class="col-lg-4 col-md-6">
                                <div class="service-item position-relative">
                                    {/* <div class="icon">
                                        <i class="bi bi-calendar4-week"></i>
                                    </div>*/}
                                    <b><h3>Spikes Data Analysis</h3></b>
                                    <img src={"assets/img/services/spikesdataanalysis.png"} style={{ height: "120px", width: "200px" }} />
                                    <p>The global, regional, and company-level volume and value of plastic Bird Spikes,
                                        a product made from UV stabilised plastic material that is designed to prevent
                                        pigeons, sea gulls, and other birds from settling on undesired surfaces.
                                        It has been estimated that the market is expected to experience a 
                                        significant growth in 2021, and is projected to reach a value of USD million in 2028, 
                                        with a compound annual growth rate (CAGR) of 6.2% by 2028.

                                        </p>
                                    <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                  
                 <div class="col-lg-4 col-md-6">
                                <div class="service-item position-relative">
                                    {/*   <div class="icon">
                                        <i class="bi bi-chat-square-text"></i>
                                    </div>*/}
                                    <b><h3>Spikes Flow Charts</h3></b>
                                    <img src={"assets/img/services/spikesflowchart.png"} style={{ height: "120px", width: "200px" }} />
                                    <p>The Bird Spikes are the most advanced and successful bird barrier spikes available on the market.
                                        The sale of the Spikezone product began in 2017. Currently, there are 8 different Bird Spikes models
                                        available to suit all budgets,
                                        levels of infestation, and bird species, such as sparrow, pigeon, and seagull.</p>
                                    <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                  
        </div>

                    </div>
                </section>
            </>
        );
    }
}
