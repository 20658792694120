import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Gallery extends Component {
    static displayName = Gallery.name;

    render() {
        return (
            <>

                <section id="portfolio" className="portfolio sections-bg">
                    <div className="container" data-aos="fade-up">



                        <div className="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order" data-aos="fade-up" data-aos-delay="100">
                            <div className="section-header">
                                <h2>Gallery</h2>

                            </div>
                            <div>
                                <ul className="portfolio-flters">
                                    <li data-filter="*" className="filter-active">All</li>
                                    <li data-filter=".filter-app">Anti Bird Net</li>
                                    <li data-filter=".filter-product">Bird Repellent</li>
                                    <li data-filter=".filter-branding">Bird Spikes</li>
                                    <li data-filter=".filter-books">Monkey Spikes</li>
                                </ul>
                            </div>

                            <div className="row gy-4 portfolio-container">

                                <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                                    <div className="portfolio-wrap">
                                        <Link to="assets/antibirdnet/blackantibrdnet.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="assets/antibirdnet/blackantibrdnet.jpg" className="img-fluid" alt="" /></Link>
                                        <div className="portfolio-info">
                                            <h4><Link to="portfolio-details.html" title="More Details">Black Bird Net</Link></h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                                    <div className="portfolio-wrap">
                                        <Link to="assets/antibirdnet/greenantibirdnet.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="assets/antibirdnet/greenantibirdnet.jpg" className="img-fluid" alt="" /></Link>
                                        <div className="portfolio-info">
                                            <h4><Link to="portfolio-details.html" title="More Details">Green Bird Net</Link></h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                                    <div className="portfolio-wrap">
                                        <Link to="assets/antibirdnet/purplebirdnet.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="assets/antibirdnet/purplebirdnet.jpg" className="img-fluid" alt="" /></Link>
                                        <div className="portfolio-info">
                                            <h4><Link to="portfolio-details.html" title="More Details">Purple Bird Net</Link></h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6 portfolio-item filter-product">
                                    <div className="portfolio-wrap">
                                        <Link to="assets/birdrepellent/hawkbirdspcarer.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="assets/birdrepellent/hawkbirdspcarer.jpg" className="img-fluid" alt="" /></Link>
                                        <div className="portfolio-info">
                                            <h4><Link to="portfolio-details.html" title="More Details">Bird Scarer</Link></h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6 portfolio-item filter-product">
                                    <div className="portfolio-wrap">
                                        <Link to="assets/birdrepellent/irritape.png" data-gallery="portfolio-gallery-app" className="glightbox"><img src="assets/birdrepellent/irritape.png" className="img-fluid" alt="" /></Link>
                                        <div className="portfolio-info">
                                            <h4><Link to="portfolio-details.html" title="More Details">Irritape</Link></h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                                    <div className="portfolio-wrap">
                                        <Link to="assets/birdspike/birdspike1.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="assets/birdspike/birdspike1.jpg" className="img-fluid" alt="" /></Link>
                                        <div className="portfolio-info">
                                            <h4><Link to="portfolio-details.html" title="More Details">Birdspike</Link></h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                                    <div className="portfolio-wrap">
                                        <Link to="assets/birdspike/birdspike2.png" data-gallery="portfolio-gallery-app" className="glightbox"><img src="assets/birdspike/birdspike2.png" className="img-fluid" alt="" /></Link>
                                        <div className="portfolio-info">
                                            <h4><Link to="portfolio-details.html" title="More Details">Birdspike</Link></h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                                    <div className="portfolio-wrap">
                                        <Link to="assets/birdspike/birdspike3.jpeg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="assets/birdspike/birdspike3.jpeg" className="img-fluid" alt="" /></Link>
                                        <div className="portfolio-info">
                                            <h4><Link to="portfolio-details.html" title="More Details">Birdspike</Link></h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                                    <div className="portfolio-wrap">
                                        <Link to="assets/birdspike/birdspike4.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="assets/birdspike/birdspike4.jpg" className="img-fluid" alt="" /></Link>
                                        <div className="portfolio-info">
                                            <h4><Link to="portfolio-details.html" title="More Details">Birdspike</Link></h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-xl-4 col-md-6 portfolio-item filter-books">
                                    <div className="portfolio-wrap">
                                        <Link to="assets/monkeyspike/monkeyspike1.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="assets/monkeyspike/monkeyspike1.jpg" className="img-fluid" alt="" /></Link>
                                        <div className="portfolio-info">
                                            <h4><Link to="portfolio-details.html" title="More Details">Birdspike</Link></h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-xl-4 col-md-6 portfolio-item filter-books">
                                    <div className="portfolio-wrap">
                                        <Link to="assets/monkeyspike/monkeyspike2.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="assets/monkeyspike/monkeyspike2.jpg" className="img-fluid" alt="" /></Link>
                                        <div className="portfolio-info">
                                            <h4><Link to="portfolio-details.html" title="More Details">Birdspike</Link></h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>




                            </div>

                        </div>

                    </div>
                </section>
            </>

        );
    }
}
