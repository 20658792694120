import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/shared/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';

//  import './custom.css'
import { AboutUs } from './components/AboutUs';

import { Antibirdnet } from './components/products/Antibirdnet';
import { Birdrepellent } from './components/products/Birdrepellent';
import { Birdspikes } from './components/products/Birdspikes';
import { MonkeySpikes } from './components/products/MonkeySpikes';
import { Services } from './components/Services';
import { Team } from './components/Team';
import { Contactus } from './components/Contactus';
import { Ourpartners } from './components/Ourpartners';
import { Blogs } from './components/Blogs';
import { Gallery } from './components/Gallery';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />
            <Route exact path='/home' component={Home} />
            <Route  path='/aboutus' component={AboutUs} />
            <Route path='/counter' component={Counter} />
            <Route path='/antibirdnet' component={Antibirdnet} />
            <Route path='/birdrepellent' component={Birdrepellent} />
            <Route path='/birdspikes' component={Birdspikes} />
            <Route path='/monkeyspikes' component={MonkeySpikes} />
            <Route path='/services' component={Services} />
            <Route path='/team' component={Team} />
            <Route path='/contactus' component={Contactus} />
            <Route path='/ourpartners' component={Ourpartners} />
            <Route path='/blogs' component={Blogs} />
            <Route path='/gallery' component={Gallery} />
           
      </Layout>
    );
  }
}
