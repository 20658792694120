import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class Home extends Component {
  static displayName = Home.name;

  render () {
      return (
        <>
              <section id="hero" className="hero">
                  <div className="container position-relative">
                      <div className="row gy-5" data-aos="fade-in">
                          <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
                              <h1 style={{ color: "white" }}><b> Welcome to <span>SpikeZone</span></b></h1>
                              <p>Pigeons are protected from concrete structures by polycarbonate bird spikes, 
                                  and birds are protected from people. Placing spikes acts as a safe barrier between human society and the feathery buddies. 
                                  Other locations where they can nest are safer for them.
                                  We produce spikes of different sizes at SpikeZone, one of the top producers of bird spikes. </p>
                              <div className="d-flex justify-content-center justify-content-lg-start">
                                  <Link to="/aboutus" className="btn-get-started">Get Started</Link>
                                  <Link to="" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></Link>
                              </div>
                          </div>
                          <div className="col-lg-6 order-1 order-lg-2">
                              <img src="assets/spikes/logo.png" style={{ borderRadius: "15px"}} className="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="100" />
        </div>
                          </div>
                      </div>

                      <div className="icon-boxes position-relative">
                          <div className="container position-relative">
                              <div className="row gy-4 mt-5">

                                  <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                      <div className="icon-box">
                                          <div className="icon"><i className="bi bi-easel"></i></div>
                                      <h4 className="title"><Link to="/antibirdnet" className="stretched-link">Anti Bird Net</Link>
                                      </h4>
                                      </div>
                                  </div>
          <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="200">
                                      <div className="icon-box">
                                          <div className="icon"><i className="bi bi-gem"></i></div>
                                      <h4 className="title"><Link to="/birdrepellent" className="stretched-link">Bird Repellent</Link></h4>
                                      </div>
                                  </div>
                        
          <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="300">
                                      <div className="icon-box">
                                          <div className="icon"><i className="bi bi-geo-alt"></i></div>
                                      <h4 className="title"><Link to="/birdspikes" className="stretched-link">Bird Spikes</Link></h4>
                                      </div>
                                  </div>
                        
          <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="500">
                                      <div className="icon-box">
                                          <div className="icon"><i className="bi bi-command"></i></div>
                                      <h4 className="title"><Link to="/monkeyspikes" className="stretched-link">Monkey Spikes</Link></h4>
                                      </div>
                                  </div>
                        
        </div>
                          </div>
                      </div>

                  
              </section>
         </>
    );
  }
}
