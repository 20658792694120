import React, { Component } from 'react';

export class AboutUs extends Component {
    static displayName = AboutUs.name;

    render() {
        return (
            <>
              

                <section id="about" class="about">
      <div class="container" data-aos="fade-up">

                        <div class="section-header">
                            <h2>Welcome To SpikeZone</h2>
                        <h2>About Us</h2>
                            <p>
                                Pigeons are protected from concrete structures by polycarbonate bird spikes, 
                                and birds are protected from people. Placing spikes acts as a safe barrier 
                                between human society and the
                                feathery buddies. Other locations where they can nest are safer for them
                            </p>
                    </div>

                    <div class="row gy-4">
                        <div class="col-lg-6">
                            <h3>Objectives of Using Spikes</h3>
                                <img src="assets/spikes/spikes1.jpg" class="img-fluid rounded-4 mb-4" alt="" style={{width:"500px"}}/>
                             
                         </div>
                            <div class="col-lg-6">
                                <div class="content ps-0 ps-lg-5">
                                    <br /> <br />
                                    
                                    <ul>
                                        <li><i class="bi bi-check-circle-fill"></i>
                                           <p>The feathered buddies are scared off by the pigeon spikes. The polycarbonate needled rods, 
                                         which are clearly visible from the sky, deter birds from perching on attics and open boundary walls. During their high-altitude flights, birds may see these rods and alter their landing locations to safer locations.
                                    Additionally, even if birds accidently land on these rods, the spikes won't hurt them.</p></li>
                                        <li><i class="bi bi-check-circle-fill"></i>
                                            These poles, which are made of polycarbonate, can take the weight of a full-size pigeon without breaking.
                                    Additionally, as soon as the bird leaps back, the rods it pressed will return to their original size and location.
                                    Additionally, you won't need any assistance installing these spikes on boundary walls.
                                    You can trust our brand because SpikeZone is one of the top manufacturers of anti-bird nets..</li>
                                       </ul>
                                   
                                    </div>
                                </div>
                        </div>




                        <div class="section-header">
                          
                            <p>
                               SpikeZone is one of the top producers of bird spikes, 
                                 but we also produce spikes in all shapes and sizes. We also make an effort to keep costs low and complexity high.
                                Our goal is to deliver the greatest product that is easy to use, 
                                  inexpensive to purchase, and requires little maintenance.
                            </p>
                        </div>

                        <div class="row gy-4">
                            <div class="col-lg-6">
                                <h3>Durability of The Product</h3>
                                <img src="assets/spikes/durability.jpg" class="img-fluid rounded-4 mb-4" alt="" style={{ width: "500px" }} />

                            </div>
                            <div class="col-lg-6">
                                <div class="content ps-0 ps-lg-5">
                                    <br /> <br />  <br /> <br />

                                    <ul>
                                        <li><i class="bi bi-check-circle-fill"></i>
                                            <p>Spikes must be installed on concrete walls, and installing polycarbonate rods on concrete is simpler. 
                                                These rods also properly align and stay fastened in position. Before releasing its items for sale,
                                                SpikeZone checks their durability. Even after prolonged use, the pigeon spikes remain in place.</p></li>
                                        <li><i class="bi bi-check-circle-fill"></i>
                                            Even the monkeys that are frequently seen scurrying around near to populated areas can be fitted with spikes. 
                                            Our company, SpikeZone, is one of the top producers of monkey spikes.
                                            Bird netting are another application for these polycarbonate spikes.  </li>
                                    </ul>

                                </div>
                            </div>
                        </div>



















                        </div>
    </section>
            </>
        );
    }
}
