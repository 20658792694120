import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Blogs extends Component {
    static displayName = Blogs.name;

    render() {
        return (
            <>
                <section id="blog" className="blog">
                    <div className="container" data-aos="fade-up">

                        <div className="row gy-4 posts-list">


                            <div className="col-xl-4 col-md-6">
                                <article>

                                    <div className="post-img">
                                        <img src="assets/antibirdnet/blackantibrdnet.jpg" alt="" className="img-fluid"/>
              </div>

                                        <p className="post-category">Anti BIrd Net</p>

                                       <p align="justify">
                                        <Link to="#"> Anti-Bird Nets are highly effective 
                                            in deterring the harmful effects of birds on agricultural produce,
                                            such as crops, fruits, and vegetables. 
                                            The nets are designed to be extremely fine, flexible, 
                                            and robust, thus safeguarding
                                            all types of agricultural yields.</Link>
                                        </p>

                                        <div className="d-flex align-items-center">
                                        <img src="assets/antibirdnet/blackantibrdnet.jpg" alt="" className="img-fluid post-author-img flex-shrink-0"/>
                                                <div className="post-meta">
                                                    <p className="post-author-list">Anti Bird Net</p>
                                                    <p className="post-date">
                                                        <time datetime="2022-01-01">Sept 1, 2023</time>
                                                    </p>
                                                </div>
              </div>

            </article>
                                   

                            </div>     
                          
                          
                        </div>
                    </div>
                    </section>
              </>                          
         
        );
    }
}
