import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class Birdspikes extends Component {
    static displayName = Birdspikes.name;

    render() {
        return (
            <>

                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Bird Spikes</h2>
                            <p align="justify">


                                A Bird Control Spike, also referred to as an Anti-roosting Spike, 
                                Pigeon Spike, or Roost Modification, is a device composed 
                                of long needle-like rods designed for the purpose of
                                controlling birds. It can be affixed to a building's 
                                ledge, street lighting, or commercial signage to deter
                                wild or wild-bred birds from roosting or perching. 
                                Birds can produce a large amount of unsightly, unsanitary,
                                and unsanitary feces, and some species of birds have 
                                extremely loud calls which can be disruptive to nearby
                                residents, particularly at night. Consequently, 
                                Bird Control Spikes are employed to discourage 
                                these birds without causing harm to them or killing 
                                them. In some cases, 
                                birds have used Bird Control Spikes in their nests.

                            </p>
                        </div>



                    </div>

                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4 team" id="team" >

                            <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                                <div className="member">
                                    <img src="assets/birdspike/birdspike1.jpg" className="img-fluid" alt="" />

                                    <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                                <div className="member">
                                    <img src="assets/birdspike/birdspike2.png" className="img-fluid" alt="" />

                                    <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="300">
                                <div className="member">
                                    <img src="assets/birdspike/birdspike3.jpeg" className="img-fluid" alt="" />

                                    <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="300">
                                <div className="member">
                                    <img src="assets/birdspike/birdspike4.jpg" className="img-fluid" alt="" />

                                    <div className="social">
                                        <Link to=""><i className="bi bi-twitter"></i></Link>
                                        <Link to=""><i className="bi bi-facebook"></i></Link>
                                        <Link to=""><i className="bi bi-instagram"></i></Link>
                                        <Link to=""><i className="bi bi-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
            </>
        );
    }
}
